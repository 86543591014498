<template>
  <div id="app">
    <!-- TURN THIS BACK ON
      <password-modal
      v-if="!isAuthenticated"
      @authenticationSuccess="handleAuthentication"
    />
  -->
    <nav ><!--// v-if="isAuthenticated" PASTE IT BACK-->
      <img class="logo" :src="logo" alt="Youtubista.pro" />
      <router-link to="/">Dashboard</router-link>
    </nav>
    <router-view  /> <!--// v-if="isAuthenticated" PASTE IT BACK-->
  </div>
</template>

<script>
import logo from "@/assets/logo.png";
// import PasswordModal from "./components/passwordModal.vue"; // Make sure the import path matches the file location

export default {
  components: {
   // "password-modal": PasswordModal, TURN THIS BACK ON
  },
  data() {
    return {
      isAuthenticated: true,
      logo: logo,
    };
  },
  methods: {
    handleAuthentication(authSuccess) {
      if (authSuccess) {
        this.isAuthenticated = true;
      }
    },
  },
  mounted() {
    const auth = localStorage.getItem("isAuthenticated");
    console.log(auth);
    this.isAuthenticated = auth;
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  color: #fff;
}
* {
  font-family: "Lato", sans-serif;
}
body {
  margin: 0;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    text-decoration: none;

    &.router-link-exact-active {
      color: #fff;
    }
  }
  .logo {
    width: 32px;
    margin-right: 24px;
  }
}
</style>
