<template>
  <div class="home">
    <div class="placeholderContainer">
      <div id="emptystate">
        <input
          id="idInput"
          v-model="videoId"
          placeholder="Please enter a Youtube video ID"
        />
        <button class="primary" @click="fetchVideoTitle">Add video</button>
      </div>
      <div class="videoContent">
        <div v-if="videoTitle" class="video-responsive">
          <iframe
            width="240"
            height="120"
            :src="`https://www.youtube.com/embed/${videoId}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
          <p>Last updated: {{ updateDate }}</p>
        </div>

        <div class="text" v-if="videoTitle">
          <h2>{{ videoTitle }}</h2>

          <div class="basicInfo" v-if="videoTitle">
            <p>Views: {{ viewCounter }}</p>
            <p>Comments: {{ commentCounter }}</p>
            <p>Score: {{ sentimentScore }}</p>
            <p>Topics:</p>
            <div v-for="(topic, index) in commentTopics" :key="index">
              <p class="topics">{{ topic }}</p>
            </div>
          </div>

          <div class="advancedInfo" v-if="videoTitle">
            <h3>Sentiment Analysis</h3>
            <button
              class="primary"
              @click="getSentimentAnalysis"
              :disabled="isLoadingAI"
            >
              Get Sentiment Analysis
            </button>
            <p>{{ sentimentText }}</p>
            <h3>Questions</h3>
            <h3>Topic ideas</h3>
          </div>

          <div class="comments">
            <div class="header">
              <h3>Comments</h3>
              <p id="openComments" @click="toggleComments">
                {{ isCommentsHidden ? "+" : "-" }}
              </p>
            </div>
            <div
              class="comments-container"
              :class="{ hidden: isCommentsHidden }"
              v-if="videoTitle"
            >
              <div
                class="comment"
                v-for="commentThread in paginatedComments"
                :key="commentThread.id"
              >
                <div class="comment-avatar">
                  <img
                    :src="
                      commentThread.snippet.topLevelComment.snippet
                        .authorProfileImageUrl
                    "
                    alt="Avatar"
                  />
                </div>
                <div class="comment-content">
                  <div class="comment-author">
                    {{
                      commentThread.snippet.topLevelComment.snippet
                        .authorDisplayName
                    }}
                  </div>
                  <div class="comment-text">
                    {{
                      commentThread.snippet.topLevelComment.snippet.textOriginal
                    }}
                  </div>
                  <div class="comment-replies" v-if="commentThread.replies">
                    <div
                      class="reply"
                      v-for="reply in commentThread.replies.comments"
                      :key="reply.id"
                    >
                      <div class="reply-avatar">
                        <img
                          :src="reply.snippet.authorProfileImageUrl"
                          alt="Avatar"
                        />
                      </div>
                      <div class="reply-content">
                        <div class="reply-author">
                          {{ reply.snippet.authorDisplayName }}
                        </div>
                        <div class="reply-text">
                          {{ reply.snippet.textOriginal }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 15px;
                "
              >
                <nav aria-label="Page navigation example">
                  <button type="button" class="page-link">Previous</button>

                  <button type="button" class="page-link">1</button>

                  <button
                    type="button"
                    @click="fetchComments"
                    :disabled="!nextPageToken"
                    class="page-link"
                  >
                    Fetch More Comments
                  </button>
                </nav>
              </div> -->
              <div style="display: flex; justify-content: flex-end; margin-top: 15px;">
      <nav aria-label="Page navigation example">
        <button
          type="button"
          class="primary"
          :disabled="currentPage === 1"
          @click="prevPage"
          >
          Previous
        </button>
        <span> Page {{ currentPage }} of {{ totalPages }} </span>
        <button
          type="button"
          class="primary"
          :disabled="currentPage === totalPages"
          @click="nextPage"
          >
          Next
        </button>
        <button
      v-if="nextPageToken"
      class="primary"
      @click="fetchComments"
      :disabled="!nextPageToken"
      style="margin-top: 15px;margin-left: 10px;"
    >
      Fetch More Comments
    </button>
      </nav>
    </div>
    
    
            </div>
          </div>
        </div>
      </div>
    </div>

    <button @click="showRawData">Show raw data</button>

    <button @click="signOut">Sign Out</button>
    <div id="rawdata">{{ rawData }}</div>
    <analysis
      id="AIresult"
      :isLoadingAI="isLoadingAI"
      :AIresult="AIresult"
    ></analysis>
    <div class="ama">
      <h2>Ask about the video</h2>
      <p id="amaAnswer">Answer: {{ amaAnswer }}</p>
      <input type="text" placeholder="Ask a question" v-model="amaQuestion" />
      <button @click="getAmaAnswer">Submit</button>
    </div>
  </div>
</template>

<script>
import dots from "@/assets/dots.png";
import Analysis from "../components/Analysis.vue";
import axios from "../utils/axios";

export default {
  components: { Analysis },
  data() {
    return {
      commentThreads: [],
      videoId: "",
      videoTitle: "",
      amaAnswer: "",
      dots: dots,
      rawData: null,
      viewCounter: 0,
      maxResults: 100,
      commentCounter: 0,
      AIresult: "",
      isLoadingAI: false,
      updateDate: "",
      isCommentsHidden: true,
      amaQuestion: "",
      nextPageToken: null,
      commentTopics: [],
      sentimentScore: [],
      sentimentText: [],
      currentPage: 1,
    };
  },
  components: {
    analysis: Analysis,
  },
  computed: {
    paginatedComments() {
      console.log("Here is haseeb")
      const startIndex = (this.currentPage - 1) * this.maxResults;
      const endIndex = startIndex + this.maxResults;
      return this.commentThreads.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.commentThreads.length / this.maxResults);
    },
  },

  methods: {
    nextPage() {
      console.log("Here is adil")
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    async fetchVideoTitle() {
      try {
        const response = await axios(`/title?videoId=${this.videoId}`);
        this.fetchComments();
        const { data } = response?.data;
        this.videoTitle = data.title;
      } catch (error) {
        console.error("Error fetching video title:", error);
        this.videoTitle = "Failed to fetch video title.";
      }
    },
    parseAIResponse(response) {
      const responseArray = response.split(";");
      const sentimentScore = responseArray[0].replace("[", "").replace("]", "");
      const sentimentText = responseArray[1].replace("[", "").replace("]", "");
      const commentTopics = responseArray[2]
        .replace("[", "")
        .replace("]", "")
        .replace(/"/g, "")
        .split(", ");

      this.sentimentScore = sentimentScore;
      this.sentimentText = sentimentText;
      this.commentTopics = commentTopics;
    },
    signOut() {
      localStorage.clear();
      window.location.reload();
    },
    toggleComments() {
      this.isCommentsHidden = !this.isCommentsHidden;
    },

    getSentimentAnalysis() {
      const cleanedComments = this.commentThreads.map((thread) => {
        return {
          author: thread.snippet.topLevelComment.snippet.authorDisplayName,
          comment: thread.snippet.topLevelComment.snippet.textOriginal,
        };
      });
      this.isLoadingAI = true;

      axios({
        url: "/sentimentAnalysisAI",
        method: "POST",
        data: { comments: cleanedComments },
      })
        .then((response) => {
          if (response.data.status) {
            this.AIresult = response.data.data.replace(/\n\n/g, "<br />");
          }
          this.isLoadingAI = false;
          this.parseAIResponse(this.AIresult);
        })
        .catch((error) => {
          console.error("HomeView: ", error.message);
          this.isLoadingAI = false;
        });
    },

    getAmaAnswer() {
      const cleanedComments = this.commentThreads.map((thread) => {
        return {
          author: thread.snippet.topLevelComment.snippet.authorDisplayName,
          comment: thread.snippet.topLevelComment.snippet.textOriginal,
        };
      });
      const question = this.amaQuestion;

      axios({
        url: "/ama",
        method: "POST",
        data: { comments: cleanedComments, question },
      })
        .then((response) => {
          console.log("here is response", response);
          this.amaAnswer = response.data.data;
          // this.amaAnswer = amaAnswer;
        })
        .catch((error) => {
          console.error("Error getting AMA answer:", error);
        });
    },

    showRawData() {
      this.rawData = this.commentThreads.map((thread) => {
        return {
          author: thread.snippet.topLevelComment.snippet.authorDisplayName,
          comment: thread.snippet.topLevelComment.snippet.textOriginal,
        };
      });
      this.rawData = JSON.stringify(this.rawData, null, 2);
    },
    async fetchComments() {
      console.log("here is token ", this.nextPageToken);
      try {
        const response = await axios(
          `/comments?videoId=${this.videoId}&nextPageToken=${this.nextPageToken}&maxResults=${this.maxResults}`
        );
        console.log("here is ", response.data.data);
        if (response?.data?.data?.nextPageToken) {
          this.nextPageToken = response?.data.data?.nextPageToken;
          
        } else {
          this.nextPageToken = null;
        }
        
        const { data } = response?.data.data.items;
        // this.commentThreads = data;
        // this.commentThreads.push(data);
        this.commentThreads = [
          ...this.commentThreads,
          ...response.data.data.items,
        ];
        // this.commentThreads = response.data.data.items;

        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, "0");
        const month = String(currentDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        const year = currentDate.getFullYear();
        this.updateDate = day + "-" + month + "-" + year;
        if (this.nextPageToken) {
          this.nextPage();
        }
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    },
  },
};
</script>

<style scoped>
.home {
  background: #000;
  padding: 30px 30px;
}
.video-responsive {
  padding: 12px;
  text-align: left;
  width: 240px;
}

.comments-container {
  background-color: #181818;
  color: #fff;
  max-width: 800px;
  margin: auto;
}

.comment {
  display: flex;
  padding: 12px;
  border-bottom: 1px solid #303030;
}

.comment-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.comment-content {
  flex: 1;
}

.comment-author {
  font-weight: bold;
  margin-bottom: 4px;
}

.comment-text {
  font-size: 0.9em;
  margin-bottom: 8px;
}

.comment-replies {
  margin-top: 8px;
}

.reply {
  display: flex;
  margin-top: 4px;
  padding-left: 48px;
}

.reply-avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.reply-content {
  flex: 1;
}

.reply-author {
  font-weight: bold;
  font-size: 0.85em;
}

.reply-text {
  font-size: 0.85em;
}

.placeholderContainer {
  width: 100%;
  border: 1px solid #ffca01;
  border-radius: 4px;
  background: #000;
  min-height: 100px;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 24px 0px;
  position: relative;
  flex-direction: column;
}

#idInput {
  width: 300px;
  border-color: black;
  border-bottom: 1px solid #ffca01;
  border-right: 1px solid #000;
  padding: 4px;
  margin: 12px 0px;
  background: #000;
  color: #fff;
  margin-right: 12px;
  font-size: 16px;
}
#idInput::placeholder {
  color: #cc9e01;
  font-style: italic;
}

#idInput:focus {
  border-bottom: 1px solid #ffca01;
  outline: none;
}

button.primary {
  outline: none;
  border: none;
  border-radius: 0px;
  background: linear-gradient(to right, #ffca01, #cc9e01);
  color: black;
  padding: 6px 24px;
  font-size: 14px;
  border-radius: 4px;
}

button.primary:hover {
  cursor: pointer;
  background: linear-gradient(to left, #e80c80, #ffca01);
}

#emptystate {
  display: block;
}
.videoContent {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
}
.text {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: calc(100% - 240px);
  h2 {
    margin: 0px;
    text-align: left;
  }
}

.basicInfo {
  display: flex;
  flex-direction: row;
  p {
    margin: 4px 16px 4px 0px;
  }
}
.topics {
  background: #ffca01;
  color: black;
  padding: 4px;
  margin: 4px;
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  margin-right: 4px;
}
.advancedInfo {
  p {
    text-align: left;
  }
  h3 {
    margin: 0px;
    margin-top: 12px;
    text-align: left;
  }
}

.comments {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
    background-color: #cc9e01;
    margin-top: 8px;
    border-radius: 4px;
    color: black;
    margin-left: -8px;
    padding: 0px 8px;
    h3 {
      margin: 0px;
      margin-top: 4px;
    }
    p {
      float: right;
      font-weight: bold;
      font-size: 24px;
      margin: 0px;
    }
  }
}

.hidden {
  display: none;
}
/* pagination  */
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}
.offset {
  width: 500px !important;
  margin: 20px auto;
}
</style>
