import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://youtubeai-server.vercel.app/api",
  // baseURL: "http://localhost:3000/api",
  withCredentials: false,
});

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      Accept: "application/json",
      //   Authorization: `Bearer ${userToken()}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(undefined, async (error) => {
  // Check if there is a 401 and logout if there is
  if (error?.response?.status === 401) {
    console.log("Logged Out");
    // gotToLogin();
    // this.$store.dispatch('');
  }
  return Promise.reject(error);
});

export default axiosInstance;
