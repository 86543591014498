<template>
  <div>
    <h4 v-if="!isLoadingAI && AIresult">Youtubista AI Response</h4>
    <p v-if="isLoadingAI">
      Youtubista is analysing the comments... Please wait.
    </p>
    <p v-if="AIresult && !isLoadingAI" v-html="AIresult"></p>
  </div>
</template>

<script>
export default {
  name: "Analysis",
  data() {
    return {
      showPopup: true,
    };
  },
  props: {
    AIresult: String,
    isLoadingAI: Boolean,
  },
  methods: {
    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>

<style>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.popup-content {
  text-align: center;
}

.popup button {
  margin-top: 10px;
}
</style>
